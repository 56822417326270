@font-face {
  font-family: "icomoon";
  src: url("/assets/fonts/icomoon.eot?8dexf9");
  src: url("/assets/fonts/icomoon.eot?8dexf9#iefix") format("embedded-opentype"),
    url("/assets/fonts/icomoon.ttf?8dexf9") format("truetype"),
    url("/assets/fonts/icomoon.woff?8dexf9") format("woff"),
    url("/assets/fonts/icomoon.svg?8dexf9#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "r-icons";
  src: url("/assets/fonts/r-icons/r-icons.eot");
  src: url("assets/fonts/r-icons/r-icons.eot") format("embedded-opentype"),
    url("/assets/fonts/r-icons/r-icons.ttf") format("truetype"),
    url("/assets/fonts/r-icons/r-icons.woff") format("woff"),
    url("/assets/fonts/r-icons/r-icons.svg?") format("svg");
  font-weight: normal;
  font-style: normal;
}

[class^="r-icon-"],
[class*=" r-icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "r-icons" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  text-align: center;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.r-icon-smileFaceFilled:before {
  content: "\e919";
}
.r-icon-unsmileFaceFilled:before {
  content: "\e91a";
}
.r-icon-checkbox_checked:before {
  content: "\e910";
}
.r-icon-checkbox_unchecked:before {
  content: "\e911";
}
.r-icon-connection:before {
  content: "\e912";
}
.r-icon-contemplation:before {
  content: "\e913";
}
.r-icon-fitnes:before {
  content: "\e914";
}
.r-icon-flow:before {
  content: "\e915";
}
.r-icon-nutrition:before {
  content: "\e916";
}
.r-icon-positivity:before {
  content: "\e917";
}
.r-icon-sleep:before {
  content: "\e918";
}
.r-icon-play:before {
  content: "\e90f";
}
.r-icon-chart:before {
  content: "\e90a";
}
.r-icon-gear:before {
  content: "\e90b";
}
.r-icon-neutralFace:before {
  content: "\e90c";
}
.r-icon-smileFace:before {
  content: "\e90d";
}
.r-icon-unsmileFace:before {
  content: "\e90e";
}
.r-icon-resourcesItem:before {
  content: "\e909";
}
.r-icon-chevronRight:before {
  content: "\e900";
}
.r-icon-goal2:before {
  content: "\e901";
}
.r-icon-goalsFilled:before {
  content: "\e902";
}
.r-icon-homeFilled:before {
  content: "\e903";
}
.r-icon-meditationFilled:before {
  content: "\e904";
}
.r-icon-resource2:before {
  content: "\e905";
}
.r-icon-resourcesFilled:before {
  content: "\e906";
}
.r-icon-video2:before {
  content: "\e907";
}
.r-icon-videosFilled:before {
  content: "\e908";
}

$desktop-breakpoint: 48em;
:root {
  font-size: 16px;
  --cls-pink: #be2675;
  --cls-purple: #3c1f7a;
  --cls-pink-second: #c22697;
  --cls-purple-second: #7408b1;
  --cls-black: #000000;
  --cls-bg: #f5f5f5;
  --cls-white: #ffffff;
  --cls-yellow: #ffd739;
  --cls-blue: #7eb7b2;
  --cls-blue--60-a: #7eb7b299;
  --cls-orange: #d07429;
  --cls-hr: #dadada;
  --bottom-navbar-height: 70px;

  --grd-pink: linear-gradient(90deg, var(--cls-purple) 0%, var(--cls-pink) 75%);

  --grd-pink-45: linear-gradient(
    45deg,
    var(--cls-purple) 0%,
    var(--cls-pink) 100%
  );

  --grd-pink-second: linear-gradient(
    90deg,
    var(--cls-purple-second) 0%,
    var(--cls-pink-second) 100%
  );
  --grd-pink-darker: linear-gradient(90deg, #290675 0%, #b60661 100%);

  --grd-orange: linear-gradient(
    231.88deg,
    var(--cls-orange) 15.84%,
    var(--cls-pink) 96.05%
  );
  --grd-primary: var(--grd-pink);
  --grd-primary-darker: var(--grd-pink-darker);
  --grd-secondary: var(--grd-orange);
  --trs-main: cubic-bezier(0.19, 1, 0.22, 1);
  --trs-secondary: ease-in-out;

  --ff-title: "Poppins", "Lora", HelveticaNeue, Helvetica, Arial, sans-serif;
  --ff-title-sub: "Lora", "Poppins", HelveticaNeue, Helvetica, Arial, sans-serif;
  --ff-habbit: "Lora", "Poppins", HelveticaNeue, Helvetica, Arial, sans-serif;
  --ff-body: ui-rounded, BlinkMacSystemFont, "M PLUS Rounded 1c",
    "San Francisco", HelveticaNeue, Helvetica, Arial, sans-serif;
  --fw-body: 400;
  --fw-solid-body: 500;
  --fw-title: 700;
  --fw-bold: 600;
  --fw-extra-bold: 800;
  --fw-number: 800;
  --border-radius-primary: 20px;
  --border-radius-secondary: 8px;
  --border-radius-medium: 16px;
  --border-radius-small: 4px;
  --strict-main-padding: 1rem;
  --strict-x2-padding: calc(var(--strict-main-padding) * 2);
  --strict-half-padding: calc(var(--strict-main-padding) / 2);
  --fs-body: 1.0625rem;
  --fs-footnote: 0.8125rem;
  --fs-subhead: 0.9375rem;
  --fs-title-13: 0.8125rem;
  --fs-title-16: 1rem;
  --fs-title-20: 1.25rem;
  --fs-title-22: 1.375rem;
  --fs-title-24: 1.5rem;
  --fs-title-27: 1.6875rem;
  --fs-title-28: 1.75rem;
  --fs-title-32: 2rem;
  --safe-area-inset-top: env(safe-area-inset-top, 0px);

  --resp-max-width: 900px;
  @media screen and (min-width: 37.5em) {
    --bottom-navbar-height: 0px;
  }
}

*,
*:before,
*:after {
  box-sizing: border-box;
}
html {
  font-size: 16px;
  font-family: var(--ff-body);
  font-weight: var(--fw-body);
  &.phone_size {
    width: 420px;
    position: relative;
    margin: 0 auto;
    background: #cdcccc;
    .bottom_footer {
      position: sticky;
    }
  }
}

html,
body,
div,
span,
object,
iframe,
figure,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
code,
em,
img,
small,
strike,
strong,
sub,
sup,
tt,
b,
u,
i,
ol,
ul,
li,
fieldset,
form,
label,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
main,
canvas,
embed,
footer,
header,
nav,
section,
fieldset,
legend,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font-family: inherit;
  font-weight: inherit;
  vertical-align: baseline;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  text-size-adjust: none;
}

button,
input[type="submit"],
input[type="reset"] {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}

footer,
header,
nav,
section,
main {
  display: block;
}

body {
  line-height: 1;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

input {
  -webkit-appearance: none;
  border-radius: 0;
}

a,
a:hover,
a:focus {
  color: inherit;
  text-decoration: none;
}

.material-symbols-rounded {
  font-variation-settings: "FILL" 1, "wght" 700, "GRAD" 0, "opsz" 48;
  font-display: block;
}

// main {
//   padding: max(0px,env(safe-area-inset-top)) max(0px,env(safe-area-inset-right)) max(0px,env(safe-area-inset-bottom)) max(0px,env(safe-area-inset-left));
// }
// .greetings{
//   padding-left: max(0px,env(safe-area-inset-left));
// }

@supports (padding-top: constant(safe-area-inset-top)) {
  .main_container:not(.app_modal) {
    --safe-area-inset-top: constant(safe-area-inset-top);
    // height: calc(100% + var(--safe-area-inset-top));
    // padding-top: max(1.5rem, var(--safe-area-inset-top)) !important;
  }
}
@supports (padding-top: env(safe-area-inset-top)) {
  .main_container:not(.app_modal) {
    --safe-area-inset-top: env(safe-area-inset-top);
    // padding-top: var(--safe-area-inset-top) !important;
    // padding-top: max(1.5rem, var(--safe-area-inset-top)) !important;
    // height: calc(100% + var(--safe-area-inset-top));
    // padding-top: max(0px,env(safe-area-inset-top)) !important;
  }
}

body {
  font-family: var(--ff-body);
  font-weight: var(--fw-body);
  min-height: 100vh;
  min-height: 100dvh;
  overflow: hidden;
  letter-spacing: -0.24px;
  background: var(--cls-bg);
}

.title {
  font-family: var(--ff-title);
  font-weight: var(--fw-title);
}
.rerror{
  font-size: var(--fs-title-24);
  font-weight: var(--fw-bold);
  color: rgb(216, 40, 40);
}

app-root {
  height: 100vh;
  height: 100dvh;
  overflow: hidden;
  background: var(--cls-bg);
  background: #7eb7b2;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr auto;
  grid-template-areas:
    "header"
    "main"
    "footer";

  & > aside {
    display: none;
  }

  & > header {
    grid-area: header;
  }

  & > main {
    position: relative;
    // display: grid;
    // grid-auto-flow: column;
    // grid-auto-columns: 1fr;
    scroll-behavior: smooth;
    overflow: auto;
    overflow-x: hidden;
    grid-area: main;
    // padding: 15px 5px 10px 5px;
    // *{
    //   grid-column: 1 / 2;
    //   grid-row: 1 / 2;
    // }
    &.standalone{
      grid-column: 1 / -1;
      grid-row: 1 / -1;
    }
    &.no-scroll {
      overflow: hidden;
    }
  }

  & > footer {
    grid-area: footer;
    z-index: 99;
    -webkit-transform: translateZ(0);
    max-height: var(--bottom-navbar-height);
  }

  nav {
    img {
      display: none;
    }
    .bottom-navbar li.nav-myaccount {
      display: none;
    }
  }

  .bottom-navbar {
    background: var(--cls-bg);
    max-height: 75px;
    height: var(--bottom-navbar-height);
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: 1fr;
    align-items: center;
    justify-content: center;
    justify-items: center;
    align-content: center;
    padding: 0.3em 0 1em 0;
    li {
      display: grid;
      justify-content: center;
      justify-items: center;
      grid-template-rows: 1fr auto;
      color: var(--cls-purple);
      gap: 0.1rem;
      width: 100%;
      opacity: 0.5;
      cursor: pointer;
      transition: all 2s var(--trs-main);
      &.active,
      &:hover,
      &:focus {
        opacity: 1;
      }
      img {
        height: 32px;
        aspect-ratio: 1 / 1;
        margin: 0.375rem 0;
      }
      :nth-child(1) {
        font-size: 2rem;
      }
      :nth-child(2) {
        //title
        font-size: 0.8rem;
        font-weight: var(--fw-bold);
      }
      @media all and (min-width: $desktop-breakpoint) {
        :nth-child(1) {
          font-size: 2.2rem;
        }
        :nth-child(2) {
          font-size: 1rem;
        }
      }
    }
  }

  .bg_image,.bg_image_big {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    img {
      width: 100%;
      height: 100%;
      aspect-ratio: 9/16;
      object-fit: cover;
      object-position: 50% 50%;
      // height: 70vh;
    }
  }
  .bg_image_big{
    display: none;
  }

  .page {
    // position: relative;
    width: 100%;
    // transform: translate3d(0,0,0);
    //height:100%;
    &.fixed {
      position: fixed;
      overflow: hidden;
    }
    &.bg_margin {
      padding-top: 60vh;
      padding-top: 60dvh;
      padding-top: 45vh;
      padding-top: 45dvh;
      &.meditations {
        padding-top: 40vh;
        padding-top: 40dvh;
      }
    }

    .main_container:not(.app_modal) {
      position: relative;
      padding: 1.5rem 0;
      font-size: 2rem;
      text-align: center;
      height: 100%;
      width: 100%;
      max-width: var(--resp-max-width);
      margin: 0 auto;
      .title {
      }
      &.blurry {
        // transition: blur 20s var(--trs-main);
        z-index: 99;
        border-radius: var(--border-radius-medium) var(--border-radius-medium) 0
          0;
        background: rgba(165, 240, 233, 0.3);
        box-shadow: 0px -3px 11px rgba(0, 0, 0, 0.12);
        backdrop-filter: blur(5.5px);
        backdrop-filter: blur(25px);
        backdrop-filter: blur(18px);

        // background: rgba(255,255,255,0.1);
        // background: var(--cls-blue--60-a);
        // backdrop-filter: blur(16px);
      }
      .top_control {
        display: grid;
        width: 100%;
        grid-template-columns: 20% 80%;
        align-content: center;
        align-items: center;
        justify-items: start;
        &__back_button {
          cursor: pointer;
          color: inherit;
          display: grid;
          grid-auto-flow: column;
          align-items: center;
          grid-column: 1 / 2;
          grid-row: 1 / -1;
          margin-left: -7px;
          gap: var(--strict-half-padding);
          & > span {
            font-size: var(--fs-title-20);
          }
        }
      }
    }
  }
}
//// Media queries going here
@media screen and (min-width: 37.5em) {
  //tablets screen
  
  app-root {
    grid-template-areas:
      "footer main"
      "footer main";
    // grid-template-areas:
    // "aside header"
    // "aside main";
    grid-template-columns: auto 1fr;
    grid-template-rows: 1fr 1fr;
    & > footer {
      height: 100%;
      max-height: none;
    }
    .home-account[data-hide-tab="true"] {
      display: none;
    }

    nav {
      height: 100%;
      border-right: 1px solid var(--cls-purple);
      background: var(--cls-bg);
      img {
        display: block;
        margin: 0 auto;
        width: 64px;
        padding-top: var(--strict-main-padding);
      }
      .bottom-navbar li.nav-myaccount {
        display: inherit;
      }
    }
    .bottom-navbar {
      max-height: none;
      height: 100%;
      grid-auto-flow: row;
      gap: var(--strict-main-padding);
      padding: var(--strict-main-padding);
      align-content: start;
      margin-top: var(--strict-x2-padding);
    }
    .bottom_footer {
      bottom: 0;
    }

    .account__form hr {
      &.full_width {
        &::before,
        &::after {
          width: 0;
        }
      }
    }

  }
}
@media screen and (min-width: 64em) {
  // desktop go 1024px
  
  app-root {
    grid-template-columns: min(20%, 350px) 1fr;

    & > footer {
      // min-width: 20%;
      nav{
        padding: var(--strict-main-padding) var(--strict-main-padding) var(--strict-main-padding) var(--strict-x2-padding);
        .bottom-navbar{
          gap: var(--strict-x2-padding);
          li :nth-child(1) {
            font-size: 1.2rem;
          }
          li :nth-child(2) {
            font-weight: var(--fw-body);
            font-size: var(--fs-title-16);
            white-space: nowrap;
          }
        }
        img{
          margin: var(--strict-x2-padding) 0 0 var(--strict-main-padding);
        }
      }
    }
    

    .bg_image,.bg_image_big {
      display: none;
      img:not(.standalone) {
        object-position: 100px 50%;
      }
    }
    .bg_image_big{
      display: initial;
    }
    // .greetings{
    //   max-width: 1100px;
    //   margin-left: auto;
    //   margin-right: auto;
    // }
    .page > .main_container:not(.app_modal) {
      max-width: initial;
      > *{
        max-width: var(--resp-max-width);
        // margin: 0 auto;
        margin-left: auto;
        margin-right: auto;
      }

    }
    .page.home > .main_container:not(.app_modal).blurry {
      background: transparent;
      border: 0;
      backdrop-filter: none;
      box-shadow: none;
      .arrow_scroll {
        display: none;
      }
      .grid{
        margin-top: 0;
      }
    }
    .bottom-navbar {
      margin-right: var(--strict-x2-padding);
      margin-top: calc(var(--strict-x2-padding) * 2);
      li{
        grid-template-rows: initial;
        grid-template-columns: auto 1fr;
        align-items: center;
        justify-items: start;
        gap: var(--strict-main-padding);
        font-weight: var(--fw-bold);
        text-transform: uppercase;
      }
    }
  }
}
// Media queries going here END
///

.box_button {
  display: inline-block;
  position: relative;
  min-width: 100px;
  padding: 10px 25px;
  font-size: 1.2rem;
  border-radius: var(--border-radius-primary);
  color: #000;
  font-family: var(--ff-title);
  font-weight: var(--fw-title);
  background: var(--grd-pink-second);
  color: var(--cls-white);
  border: none;
  outline: 0;
  transition: all 1s var(--trs-main);
  &:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    border-radius: var(--border-radius-primary);
    transition: all 1s var(--trs-main);
  }
  &:hover,
  &:focus,
  &:active {
    transform: scale(1.05);
  }
  &:hover:before,
  &:focus:before {
    background: rgba(255, 255, 255, 0.07);
  }
  &:active:before {
    background: rgba(255, 255, 255, 0.09);
  }
}

.small_box_button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px var(--strict-half-padding);
  gap: var(--strict-half-padding);
  background: var(--cls-pink);
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.12);
  border-radius: var(--border-radius-small);
  color: var(--cls-white);
  font-weight: var(--fw-solid-body);
  font-size: 0.75rem;
  transition: all 1s var(--trs-main);
  &--invert {
    color: var(--cls-pink);
    background: var(--cls-white);
  }
}

.greetings {
  padding: 0 var(--strict-main-padding);

  margin-bottom: var(--strict-main-padding);
  color: var(--cls-white);
  &__phrase {
    font-family: var(--ff-title-sub);
    font-weight: var(--fw-body);
    font-style: italic;
    font-size: var(--fs-title-24);
    // line-height: 31px;
  }
  &__name {
    font-size: var(--fs-title-22);
    font-family: var(--ff-title);
    font-weight: var(--fw-bold);
    line-height: 33px;
  }
}
.horizontal_delimeter {
  width: 100%;
  height: 1px;
  background: var(--cls-orange);
  margin: 0 auto;
}

.action {
  width: 100%;
  display: grid;
  text-align: left;
  gap: 1rem;
  grid-template-columns: auto 1fr auto;
  background: var(--cls-pink);
  background-image: var(--grd-pink-45);
  color: white;
  border: 1px solid white;
  border-radius: calc(var(--border-radius-medium) * 2);
  align-items: center;
  padding: 0.5rem 2rem;
  margin-bottom: var(--strict-main-padding);
  &:last-child {
    margin-bottom: 0;
  }
  color: var(--cls-white);
  cursor: pointer;
  transition: transform 1s var(--trs-main);
  &:hover,
  &:focus,
  &:active {
    // padding: .7em;
    transform: scale(1.02);
  }
  .icon {
    display: flex;
    color: var(--cls-white);
    // background: var(--cls-white);
    width: 36px;
    height: 36px;
    border-radius: 50%;
    justify-content: center;
    align-items: center;
    span {
      font-size: 1.125rem;
      font-size: 1.75rem;
      font-size: 2rem;
    }
  }
  .text {
    overflow: hidden;
  }
  .title,
  &--simple-button {
    font-weight: 500;
    font-size: var(--fs-body);
    line-height: 1.375rem;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .sub_text {
    font-family: var(--ff-title-sub);
    font-size: var(--fs-subhead);
    line-height: 1.25rem;
    margin-top: -4px;
    opacity: 0.8;
    font-style: italic;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .arrow {
    display: flex;
    justify-content: center;
    align-items: center;
    span {
      font-size: 1.725rem;
      font-size: 0.925rem;
      // margin-right: -7px;
    }
  }
  &--simple-button {
    display: block;
    text-align: center;
    min-height: 55px;
  }
  &--center-text {
    justify-items: center;
    div:nth-child(1) {
      grid-column: 1 / 2;
      grid-row: 1 / 1;
    }
    div:nth-child(2) {
      grid-column: 1 / -1;
      grid-row: 1 / -1;
    }
    div:nth-child(3) {
      grid-column: 3 / -1;
      grid-row: 1 / -1;
    }
  }
  &--inverse {
    background: var(--cls-white);
    color: var(--cls-pink);
    border: 1px var(--cls-pink) solid;
    border: 1px solid rgba(190, 38, 117, 0.5);
  }

  &--secondary {
    background: var(--cls-white);
    color: var(--cls-pink);
    border: 1px var(--cls-pink) solid;
    border: 1px solid rgba(190, 38, 117, 0.5);
    border-radius: var(--border-radius-secondary);
    padding: calc(var(--strict-main-padding) / 2) var(--strict-main-padding);
    .icon {
      color: var(--cls-pink);
      span {
        font-size: 2.375rem;
      }

      // border: 2px var(--cls-blue) solid;
    }
  }
  &--purple {
    color: var(--cls-white);
    background: var(--grd-pink-second);
    // border: 1px solid rgba(255, 255, 255, 0.58);
    border: 0;
  }
  &--clear {
    border: 0;
    color: var(--cls-white);
    background: transparent;
    margin: 0;
  }
  &--clear-border {
    border: 1px solid #ffffff80;
    border-radius: var(--border-radius-small);
    color: var(--cls-white);
    background: transparent;
    margin: 0;
  }
  &.purple_gradient {
    background: var(--grd-pink-second);
  }
}

.recommendations,
.spiral_card {
  display: flex;
  flex: 1;
  flex-direction: column;
  text-align: left;
  min-height: 300px;
  background: var(--cls-white);
  border-radius: var(--border-radius-secondary);
  font-size: 1rem;
  padding: var(--strict-main-padding);
  &__text {
    line-height: 1.5rem;
    margin-bottom: var(--strict-x2-padding);
    font-size: var(--fs-body);
    ul {
      list-style-type: disc;
      list-style-position: outside;
      margin-left: 1em;
    }
    span {
      color: var(--cls-purple);
      font-weight: var(--fw-title);
    }
  }
  &__title {
    // font-family: var(--ff-title);
    font-weight: var(--fw-title);
    color: var(--cls-purple);
    font-size: var(--fs-title-22);
    line-height: 2.75rem;

    // margin: 1em 0;
    margin: 1rem 0;
    &:nth-child(1) {
      margin-top: 0;
    }
    &--sub {
      font-size: var(--fs-body);
      color: var(--cls-orange);
      margin: 0;
    }
  }
  &__slider_controls {
    display: grid;
    margin-top: 1em;
    grid-auto-columns: auto;
    grid-auto-flow: column;
    justify-items: center;
    gap: 0.5em;
    align-content: center;
    justify-content: center;
  }
  &__slider_control {
    cursor: pointer;
    display: grid;
    justify-items: center;
    align-content: center;
    font-weight: var(--fw-number);
    color: var(--cls-pink);
    border: 1px solid currentColor;
    aspect-ratio: 1 / 1;
    height: 44px;
    background: var(--cls-white);
    border-radius: calc(var(--border-radius-secondary) / 2);
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.12);
    transition: background, color 1s var(--trs-main);

    &:hover,
    &:focus,
    &:active,
    &.active {
      color: var(--cls-white);
      background: var(--cls-pink);
      border: 0;
    }
  }
}
.spiral_card {
  margin: var(--strict-main-padding) 0;
}

.box .item_container.recommendations {
  background: var(--cls-white);
  color: var(--cls-black);
}

body.restrict_size {
  position: relative;
  max-width: 440px;
  margin: 0 auto;
  background: var(--cls-bg);
  main .page.meditations .bottom_footer {
    position: sticky !important;
    bottom: 0px !important;
  }
}

.hide_progress {
  display: contents;
  .new_cardv2--icalm,
  .new_card__buttons_groups,
  .meditations-player {
    transition: all 2s var(--trs-main);
  }

  &.active {
    .new_cardv2--icalm {
      opacity: 0;
      max-height: 0;
      padding: 0;
      margin: 0;
    }
    .new_card__buttons_groups {
      opacity: 0;
    }
    .meditations-player {
      opacity: 0;
    }
  }
}

.top_control,
.simple_select {
  color: var(--cls-pink);
  &__back_button {
  }
  &__options {
    justify-self: end;
  }
  &__option {
    position: relative;
    cursor: pointer;
  }
  &__selected_value {
    cursor: pointer;
  }
  &__select {
    cursor: pointer;
    width: 100%;
    height: 100%;
    position: absolute;
    opacity: 0;
    top: 0;
    left: 0;
  }
}
.simple_select {
  color: var(--cls-purple);
}

.animated_hide {
  transition: max-height 0.4s ease;
  transition-property: max-height, margin, padding;
  max-height: 1000px;
  overflow: hidden;
  &.hided {
    padding: 0;
    margin: 0;
    max-height: 0;
  }
}

.bottom_footer {
  position: fixed;
  cursor: pointer;
  bottom: var(--bottom-navbar-height);
  left: 0;
  width: 100%;
  // height: 44px;
  padding: var(--strict-main-padding);
  background: var(--cls-bg);
  box-shadow: 0px -3px 11px rgb(0 0 0 / 12%);
  // background: rgba(253, 245, 242, 0.9);
  z-index: 99;
  border-radius: 4px 4px 0 0;
  border-bottom: 1px solid var(--cls-hr);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: var(--fs-body);
  z-index: 100;
  transition: max-height 1s var(--trs-main);
  transition-property: max-height, margin, padding;
  max-height: 300px;
  overflow: hidden;
  &.hided {
    padding: 0;
    margin: 0;
    max-height: 0;
  }
  &__text {
    margin: var(--strict-half-padding) 0;
    span {
      font-weight: var(--fw-bold);
      color: var(--cls-pink);
      cursor: pointer;
    }
  }
}

.meditate-popup {
  position: fixed;
  pointer-events: none;
  opacity: 0;
  z-index: 0;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: opacity 1s var(--trs-main);
  &.active {
    pointer-events: all;
    opacity: 1;
    z-index: 1000;
  }
  &__overlay {
    position: fixed;
    pointer-events: none;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    background: rgba(153, 103, 80, 0.35);
    z-index: 99;
    transition: opacity 0.5s var(--trs-main);
    &.active {
      opacity: 1;
    }
  }
  &__body {
    position: relative;
    h2 {
      font-size: var(--fs-title-22);
      font-family: var(--ff-title-sub);
      text-align: center;
    }
    border-radius: var(--border-radius-primary);
    background: var(--cls-bg);
    padding: var(--strict-main-padding);
    width: 320px;
    height: auto;
    z-index: 100;
  }
  &__notifications {
    max-height: 0;
    overflow: hidden;
    opacity: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.5s var(--trs-main);
    margin-top: 0;
    justify-content: space-between;
    align-items: center;
    h2 {
      margin-right: var(--strict-main-padding);
    }
    &.active {
      opacity: 1;
      max-height: 100px;
      margin-top: var(--strict-main-padding);
    }
  }
  &__notification {
    cursor: pointer;
    font-size: var(--fs-title-16);
    font-weight: var(--fw-bold);
    gap: var(--strict-main-padding);
    // margin: 0 var(--strict-main-padding);
    // padding: var(--strict-main-padding);
    &.active {
      color: var(--cls-pink);
    }
  }
  &__notifications_container {
    display: flex;
    gap: var(--strict-main-padding);
  }
  &__notification--button {
    color: var(--cls-pink);
    background: var(--cls-white);
    border: 1px solid var(--cls-pink);
    border-radius: var(--border-radius-primary);
    transition: all 1s var(--trs-main);
    padding: var(--strict-half-padding) var(--strict-main-padding);
    &.active {
      color: var(--cls-white);
      background: var(--cls-pink);
      border: 1px solid var(--cls-pink);
    }
  }
  &__select {
    cursor: pointer;
    position: absolute;
    opacity: 0;
    top: 0;
    left: 0;
    width: 100%;
  }
  &__select_option {
    position: relative;
    text-align: center;
    margin: 0 var(--strict-main-padding);
    margin: 0 0.625rem;
    margin: 0 var(--strict-half-padding);
    cursor: pointer;
    select {
      cursor: pointer;
      position: absolute;
      opacity: 0;
      top: 0;
      left: 0;
      width: 100%;
      // padding: var(--strict-main-padding);
    }
  }
  &__value_option {
    font-size: var(--fs-title-16);
    font-weight: var(--fw-bold);
    // margin: 0 var(--strict-main-padding);
    padding: var(--strict-main-padding);
    color: var(--cls-pink);
  }
  &__buttons {
    text-align: center;
    // margin-top: var(--strict-main-padding);
  }
  &__close {
    position: absolute;
    right: var(--strict-main-padding);
    top: var(--strict-main-padding);
    cursor: pointer;
    text-align: right;
    color: var(--cls-pink);
    span {
      font-size: 2rem;
    }
  }
}

.can_click {
  cursor: pointer;
}

.videos-player {
  position: relative;
  cursor: pointer;
  font-family: var(--ff-body);
  background: var(--cls-white);
  margin: calc(var(--strict-main-padding) / 2) 0;
  border-radius: 4px;
  color: var(--cls-pink);
  box-shadow: 0px 1.2px 3.6px rgba(0, 0, 0, 0.11),
    0px 6.4px 14.4px rgba(0, 0, 0, 0.13);
  &:first-child {
    margin-top: 0;
  }
  &__main_container {
    position: relative;
    padding: var(--strict-main-padding);
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: auto 1fr;
    align-items: center;
    gap: 1.875rem;
  }

  &__icon {
    display: flex;
    color: var(--cls-pink);
    background: var(--cls-white);

    // font-size: 2rem;
    // width: 50px;
    // height: 50px;
    border-radius: 50%;
    justify-content: center;
    align-items: center;

    span {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: var(--fs-title-27);
      margin-right: 0;
      transition: all 1s var(--trs-main);
      width: 2rem;
      height: 1.875rem;
    }
    &.icon-padding {
      span:nth-child(1) {
        margin-right: 1.875rem;
        margin-right: 1.625rem;
      }
    }
  }
  &__title_container {
  }
  &__title {
    font-size: var(--fs-body);
    line-height: 1.25rem;
  }
  &__duration {
    font-size: var(--fs-footnote);
    color: var(--cls-black);
    line-height: 1rem;
  }

  .video_viewed {
    color: var(--cls-blue);
  }
}

.account__center_title {
  text-align: center;
  margin-bottom: var(--strict-half-padding);
  font-size: var(--fs-title-22);
  line-height: 1.625rem;
  color: var(--cls-purple);
  font-weight: var(--fw-bold);
}

.account__form {
  padding: var(--strict-main-padding) 0;
  // background-color: var(--cls-bg)
  fieldset {
    margin-bottom: var(--strict-x2-padding);
    // padding: var(--strict-main-padding);
    // border: 1px solid #ccc;
    // border-radius: 5px;
  }
  legend {
    font-size: var(--fs-title-24);
    font-weight: var(--fw-title);
    font-family: var(--ff-title);
    border: 0;
    padding: var(--strict-main-padding) 0;
    // padding-top: var(--strict-x2-padding);
    margin: 0;
    &.main_title {
      margin-bottom: var(--strict-x2-padding);
    }
  }
  hr {
    margin: var(--strict-x2-padding) 0;
    &.full_width {
      position: relative;
      overflow: initial;
      &::before,
      &::after {
        content: "";
        display: block;
        height: 1px;
        background-color: rgb(238, 238, 238);
        margin-top: -1px;
        position: absolute;
        top: 0;
        width: calc((100vw - 80%) / 2);
      }

      &::before {
        left: calc(-1 * (var(--strict-x2-padding)));
      }

      &::after {
        right: calc(-1 * (var(--strict-x2-padding)));
      }
    }
  }
  label {
    display: block;
    margin-bottom: var(--strict-half-padding);
    font-size: var(--fs-title-16);
    font-weight: var(--fw-bold);
  }

  input[type="text"],
  input[type="password"],
  input[type="email"],
  select {
    outline: none;
    width: 100%;
    padding: var(--strict-half-padding);
    font-size: var(--fs-title-20);
    border: 1px solid #ccc;
    border-radius: var(--border-radius-small);
    line-height: 150%;

    &:disabled {
      background: #e9e9e9;
    }
  }

  input[disabled] {
    opacity: 0.5;
  }

  select {
    position: relative;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    background-color: #f5f5f5;
    background-image: url("data:image/svg+xml,%3Csvg width='14' height='9' viewBox='0 0 14 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 1.5L7 7.5L13 1.5' stroke='%23292836' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: right 10px center;
    background-size: 14px 9px;
    border: 1px solid #ccc;
    border-radius: var(--border-radius-small);
    color: #666;
    &::after {
      position: absolute;
      content: "";
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      opacity: 0.6;
    }
  }
  &_group {
    position: relative;
    margin-bottom: var(--strict-main-padding);
  }
  &_error {
    font-size: var(--fs-footnote);
    color: #bb0000;
    margin-left: var(--strict-half-padding);
    &--abs {
      position: absolute;
      top: 6px;
      left: 0;
      z-index: 1;
    }
  }
}
